<script setup lang="ts">
import type { LinkParam, Show } from '#gql/default'
import { useElementSize } from '@vueuse/core'

interface Props {
  shows: Show[]
  linkParams?: LinkParam[]
  bookingModal?: boolean
  hideInactiveButtons?: boolean
}

const props = defineProps<Props>()

const slides = computed(() =>
  groupObjectsByKeyInto(
    props.shows.map((show) => ({
      ...show,
      isoDate: show?.beginning.substring(0, 10),
    })),
    'isoDate',
    'isoDate',
    'shows',
  ),
)

const container = ref(null)
const { width } = useElementSize(container)

const perPage = computed(() => {
  if (width.value >= TAILWIND_CONTAINER_QUERY.SIZE_3XL) {
    return 5
  } else if (width.value >= TAILWIND_CONTAINER_QUERY.SIZE_XL) {
    return 4
  } else if (width.value >= TAILWIND_CONTAINER_QUERY.SIZE_LG) {
    return 3
  } else {
    return 2
  }
})

const classes = computed(() => {
  return {
    container:
      'flex group relative px-3 sm:px-6 xl:px-8 2xl:px-8 sm:overflow-hidden',
    wrapper: 'w-full overflow-hidden',
    buttonPrev:
      'hidden sm:flex absolute dark:bg-dark bg-light data-[is-active="false"]:opacity-25 data-[is-active="false"]:pointer-events-none -translate-y-1/2 left-0 z-20 !rounded-full',
    buttonNext:
      'hidden sm:flex absolute dark:bg-dark bg-light data-[is-active="false"]:opacity-25 data-[is-active="false"]:pointer-events-none -translate-y-1/2 right-0 z-20 !rounded-full',
    slide:
      'flex flex-col grow-0 shrink-0 px-1.5 sm:px-2 space-x-4 basis-1/2 @lg:basis-1/3 @xl:basis-1/4 @3xl:basis-1/5',
  }
})

defineOptions({
  name: 'MoviePlaytimesDays',
})
</script>

<template>
  <div
    ref="container"
    class="@container group-data-[default-horizontal-spacing=true]:container-bleed sm:group-data-[default-horizontal-spacing=true]:container-spacing-horizontal"
  >
    <HorizontalMediaScroller
      :button-theme="BUTTON_THEME.SECONDARY"
      :button-size="BUTTON_SIZE.XS"
      image-selector="[data-image]"
      :classes
      :slides
      :per-page
      :hide-inactive-buttons
    >
      <template #default="{ slide }">
        <MoviePlaytimesDayList
          :shows="slide.shows"
          :date="slide.isoDate"
          :booking-modal
          :link-params
        />
      </template>
    </HorizontalMediaScroller>
  </div>
</template>
